// 
import moment from 'moment-timezone';

/**
 * dateTz adjusts the dates from one timezone to another
 *
 * @param {Date} date
 * @param {String} baseTz (base timezone, eg. America/New_York)
 * @param {String} toTz (force to timezone, eg. America/Chicago)
 * @returns {String|Date}
 * @api private
 */

const dateTz = (date, baseTz, toTz) => {
  if (!date) {
    return null
  }

  // if from or to are null or undfined, convert them to be local
  // this is purely for code brevity
  if (!baseTz) {
    // eslint-disable-next-line
    baseTz = moment.tz.guess()
  }
  if (!toTz) {
    // eslint-disable-next-line
    toTz = moment.tz.guess()
  }

  // convert to the base date and strip off the timezone
  // const baseDate = moment.tz(date, baseTz).format('YYYY-MM-DDTHH:mm:ss')
  // convert to the final timezone applying the final timezone
  return moment.tz(date, toTz).toDate()
};

// toLocal converts the date to local against the hotel's zone
const toLocal = (date, zone) => {
  if (!date) {
    return null
  }

  const local = moment.tz.guess()
  const offset = moment.tz(zone).format('Z')

  const d = moment.tz(date, 'UTC').utcOffset(offset)
  return moment.tz(d.format('YYYY-MM-DDTHH:mm:ss'), local).toDate()
}

// toUTC converts the date to UTC against the hotel's zone
// NOTE this basically adjusts the date against the local to the hotel's zone,
// which auto converts to UTC at server. You don't need the to UTC it, it will
// always UTC upon entering the database
const toUTC = (date, zone) => {
  if (!date) {
    return null
  }

  // NOTE leaving commented for reference, that was the original method used for
  // the conversion
  // const offset = moment.tz(zone).format('Z')

  const d = moment(date)
  return moment.tz(d.format('YYYY-MM-DDTHH:mm:ss'), zone).toDate()

  // return moment.tz(
  //     moment(date).format('YYYY-MM-DDTHH:mm:ss') + offset,
  //     'UTC'
  //   ).toDate()
}

/**
 * expose
 */

export default dateTz;
export { toLocal }
export { toUTC }
