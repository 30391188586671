import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get'
import set from 'lodash/set'
import { toLocal, toUTC } from '../../../utils/date-tz';
import * as propertyActions from '../../../actions/properties';
import * as sidebarActions from '../../../actions/sidebar';
import * as specialActions from '../../../actions/specials';
import HotelSpecialForm from '../../../components/HotelSpecialForm';
import Layout from '../../../layout';
import FormNavigator from '../../../components/FormNavigator';

const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
  detailsImage: PropTypes.object.isRequired,
  createSpecial: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired
};

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'date', title: 'Date & Time' },
  { id: 'image', title: 'Set an Image' },
  { id: 'cta', title: 'CTA (Call to Action)' },
  { id: 'promo', title: 'Promo Flag' },
  { id: 'details', title: 'Details Page' },
  { id: 'preview', title: 'Preview + Publish' },
];

const SpecialsNew = ({
  match: {
    params: { hotelid }
  },
  history: { push },
  createSpecial,
  hotel,
  images,
  detailsImage,
  isLoading,
  fetchProperty,
  updateSidebar
}) => {
  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    return () => {};
  }, []);

  const handleSubmit = data => {
    let { doesNotEnd, endsOn } = data;
    if (doesNotEnd) {
      endsOn = new Date(2120, 0, 1);
    }
    const body = {
      ...data,
      startsOn: toUTC(data.startsOn, hotel.timezone),
      endsOn: toUTC(endsOn, hotel.timezone),
      promo_flag:
        // eslint-disable-next-line no-nested-ternary
        data.promoFlag === 'Other' ? data.promoFlagOther : data.promoFlag,
      images,
      details: detailsImage.file
        ? {
            ...data.details,
            image: { assetId: detailsImage.file.id, alt: detailsImage.alt }
          }
        : data.details
    };
    return createSpecial(hotelid, body)
      .then(() => {
        push(`/hotels/${hotelid}/specials`);
      })
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  return (
    <Layout type="Specials" metaTitle="New Special">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <Link
            to={`/hotels/${hotel.id}/specials`}
            className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
          >
            &lt; Back to Specials
          </Link>
          <h1 className="mb-6">New Special</h1>
          <div className="mb-16">
            Use this page to create and manage Specials for {hotel.name}.
          </div>

          <HotelSpecialForm
            id="new-specials"
            hotel={hotel}
            initialValues={{
              doesNotEnd: true,
              cta: {
                type: 'Book Now',
                opensInNewWindow: true,
                addDatePickerPopup: false,
                advancedPurchaseDays: 0,
                minimumNightStay: 1
              },
              minNightStay: true,
              promoFlag: 'None',
              details: {
                cta: {
                  addDatePickerPopup: false,
                  advancedPurchaseDays: 0,
                  minimumNightStay: 1,
                  type: 'Check Availability'
                }
              },
              detailsMinNightStay: true,
              showPackage: true,
              archived: false,
              published: false
            }}
            onSubmit={handleSubmit}
          />
        </div>
      )}
      <FormNavigator
        id="new-specials-navigator"
        sections={sections}
        className="hidden xl:block mt-32 pt-16"
      />
    </Layout>
  );
};

SpecialsNew.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    specials: state.specials.data,
    images: get(state, 'images.specials-image-form.items', [])
      .filter(item => item.file)
      .map(item => ({ assetId: item.file.id, alt: item.alt })),
    detailsImage: get(state, 'images.specials-details-image.items.0', {}),
    isLoading: state.property.isLoading
  }),
  { ...propertyActions, ...specialActions, ...sidebarActions }
)(SpecialsNew);
