import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get'
import set from 'lodash/set'
import { toLocal, toUTC } from '../../../utils/date-tz';
import * as propertyActions from '../../../actions/properties';
import * as sidebarActions from '../../../actions/sidebar';
import * as categoriesActions from '../../../actions/categories';
import * as faqActions from '../../../actions/faqs';
import HotelFAQForm from '../../../components/HotelFAQForm';
import Layout from '../../../layout';
import FormNavigator from '../../../components/FormNavigator';

const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired,
  faq: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  updateFAQ: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchFAQ: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired
};

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'date', title: 'Date & Time' },
  { id: 'preview', title: 'Preview + Publish' },
];

const FAQsEdit = ({
  match: {
    params: { hotelid, id }
  },
  history: { push },
  updateFAQ,
  hotel,
  faq,
  categories,
  isLoading,
  fetchProperty,
  fetchFAQ,
  updateSidebar,
  fetchCategories
}) => {
  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    fetchCategories(hotelid);
    fetchFAQ(hotelid, id);

    return () => {};
  }, []);

  useEffect(() => {
    // convert the dates from the server UTC the hotel's timezone adjusted for
    // the local zone
    set(faq, 'start', toLocal(faq.start, hotel.timezone))
    set(faq, 'end', toLocal(faq.end, hotel.timezone))
    
    return () => {}
  }, [faq])

  const handleSubmit = data => {
    const body = {
      ...data,
      startsOn: toUTC(data.startsOn, hotel.timezone),
      endsOn: toUTC(data.endsOn, hotel.timezone)
    };
    return updateFAQ(hotelid, id, body)
      .then(() => push(`/hotels/${hotelid}/faqs`))
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  return (
    <Layout type="FAQ" metaTitle="Edit FAQ">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <Link
            to={`/hotels/${hotel.id}/faqs`}
            className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
          >
            &lt; Back to FAQs
          </Link>
          <h1 className="mb-6">{faq.question}</h1>
          <div className="mb-16">
            Use this page to edit {faq.question} FAQ for {hotel.name}.
          </div>

          <HotelFAQForm
            id="edit-faqs"
            initialValues={{
              ...faq,
              categoryId: faq.category.id,
              startsOn: faq.startsOn ? new Date(faq.startsOn) : faq.startsOn,
              endsOn: faq.endsOn ? new Date(faq.endsOn) : faq.endsOn
            }}
            categories={categories}
            onSubmit={handleSubmit}
            enableReinitialize
            keepDirtyOnReinitialize
          />
        </div>
      )}
      <FormNavigator
        id="edit-faqs-navigator"
        sections={sections}
        className=""
      />
    </Layout>
  );
};

FAQsEdit.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    faq: state.faq.data,
    categories: state.categories.data,
    isLoading:
      state.property.isLoading ||
      state.faq.isLoading ||
      state.categories.isLoading
  }),
  { ...propertyActions, ...sidebarActions, ...categoriesActions, ...faqActions }
)(FAQsEdit);
