import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get'
import set from 'lodash/set'
import { SubmissionError } from 'redux-form';
import { Link } from 'react-router-dom';
import Layout from '../../layout';
import RestaurantForm from '../../components/RestaurantForm';
import * as propertiesActions from '../../actions/properties';
import { toLocal, toUTC } from '../../utils/date-tz';

const propTypes = {
  createProperty: PropTypes.func.isRequired,
  restaurantLogo: PropTypes.object,
  history: PropTypes.object.isRequired
};

const defaultProps = {
  restaurantLogo: {}
};

const New = ({ createProperty, restaurantLogo, history: { push } }) => {
  const handleSubmit = data => {
    const body = {
      ...data,
      type: 'restaurant',
      logo: restaurantLogo.file
        ? { assetId: restaurantLogo.file.id, alt: restaurantLogo.alt }
        : null
    };

    return createProperty(body)
      .then(() => push('/restaurants'))
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  return (
    <Layout metaTitle="New Restaurant">
      <div className="w-full max-w-3xl">
        <Link
          to="/restaurants"
          className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
        >
          &lt; Back to Restaurants
        </Link>
        <h1 className="mb-6">New Restaurant</h1>
        <div className="mb-16">
          Use this page to create and manage a new restaurant.
        </div>
        <RestaurantForm
          id="new-restaurant-form"
          onSubmit={handleSubmit}
          initialValues={{ archived: false }}
        />
      </div>
    </Layout>
  );
};

New.propTypes = propTypes;
New.defaultProps = defaultProps;

export default connect(
  state => ({
    restaurantLogo: get(state, 'images.restaurant-logo-image.items.0', {})
  }),
  { ...propertiesActions }
)(New);
