import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get'
import set from 'lodash/set'
import { toLocal, toUTC } from '../../../utils/date-tz';
import Layout from '../../../layout';
import FormNavigator from '../../../components/FormNavigator';
import HotelCalendarForm from '../../../components/HotelCalendarForm';
import * as propertyActions from '../../../actions/properties';
import * as sidebarActions from '../../../actions/sidebar';
import * as calendarActions from '../../../actions/calendars';
import * as calendarEventActions from '../../../actions/calendarEvents';

const propTypes = {
  hotel: PropTypes.object.isRequired,
  calendars: PropTypes.array.isRequired,
  images: PropTypes.array.isRequired,
  detailsImage: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  createCalendarEvent: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchCalendars: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired
};

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'date', title: 'Date & Time' },
  { id: 'image', title: 'Set an Image' },
  { id: 'cta', title: 'CTA (Call to Action)' },
  { id: 'details', title: 'Internal Detail Page' },
  { id: 'preview', title: 'Preview + Publish' },
];

const New = ({
  hotel,
  calendars,
  match: {
    params: { hotelid }
  },
  history: { push },
  images,
  detailsImage,
  createCalendarEvent,
  fetchProperty,
  fetchCalendars,
  updateSidebar
}) => {
  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    fetchCalendars(hotelid);
    return () => {};
  }, []);

  const handleSubmit = data => {
    const detailType = get(data, 'details.cta.type');
    const detailTitle =
      detailType === 'Other' ? data.details.cta.titleInput : detailType;
    const body = {
      ...data,
      start: toUTC(data.start, hotel.timezone),
      end: toUTC(data.end, hotel.timezone),
      repeats: data.repeatOption === 'none' ? null : data.repeatOption,
      repeats_on: data.repeatsOn,
      images,
      details: {
        ...data.details,
        image: detailsImage.file
          ? { assetId: detailsImage.file.id, alt: detailsImage.alt }
          : null,
        cta: { ...data.details.cta, type: detailTitle }
      }
    };
    return createCalendarEvent(hotelid, body)
      .then(() => {
        push(`/hotels/${hotelid}/calendars`);
      })
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  return (
    <Layout metaTitle="New Calendar Event">
      <div className="w-full max-w-3xl">
        <Link
          to={`/hotels/${hotelid}/calendars`}
          className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
        >
          &lt; Back to Calendars
        </Link>
        <h1 className="mb-6">New Calendar Event</h1>
        <div className="mb-16">
          Use this page to create and manage Calendar Events for {hotel.name}.
        </div>
        <HotelCalendarForm
          id="new-calendar"
          className="mb-4"
          calendars={calendars}
          hotel={hotel}
          initialValues={{
            calendarId: get(calendars, '0.id'),
            allDay: false,
            repeatOption: 'none',
            repeatsOn: [],
            cta: {
              type: 'No Detail Page'
            },
            doesNotEnd: true,
            details: {
              cta: {
                type: 'None',
                opensInNewWindow: true,
                addDatePickerPopup: false,
                advancedPurchaseDays: 0,
                minimumNightStay: 1
              }
            },
            archived: false,
            published: false
          }}
          onSubmit={handleSubmit}
          enableReinitialize
          keepDirtyOnReinitialize
        />
      </div>
      <FormNavigator
        id="new-calendars-navigator"
        sections={sections}
        className=""
      />
    </Layout>
  );
};

New.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    calendars: state.calendars.data,
    images: get(state, 'images.hotel-calendar-image.items', [])
      .filter(item => item.file)
      .map(item => ({ assetId: item.file.id, alt: item.alt })),
    detailsImage: get(state, 'images.calendar-details-image.items.0', {}),
    isLoading: state.property.isLoading || state.calendars.isLoading
  }),
  {
    ...propertyActions,
    ...sidebarActions,
    ...calendarActions,
    ...calendarEventActions
  }
)(New);
