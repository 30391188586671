import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toLocal, toUTC } from '../../../utils/date-tz';
import Layout from '../../../layout';
import AddCalendarForm from '../../../components/AddCalendarForm';
import Calendar from '../../../components/common/Calendar';
import * as propertyActions from '../../../actions/properties';
import * as sidebarActions from '../../../actions/sidebar';
import * as categoryActions from '../../../actions/categories';
import * as calendarActions from '../../../actions/calendars';
import * as calendarEventActions from '../../../actions/calendarEvents';
import More, { MoreMenu, MoreMenuItem } from '../../../components/common/More';
import * as modalActions from '../../../actions/modals';
import EyeIcon from '../../../components/common/EyeIcon';

const propTypes = {
  restaurant: PropTypes.object.isRequired,
  calendars: PropTypes.array.isRequired,
  calendarEvents: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  createCalendar: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchCalendars: PropTypes.func.isRequired,
  fetchCalendarEvents: PropTypes.func.isRequired,
  updateProperty: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired
};

const Calendars = ({
  restaurant,
  calendars,
  calendarEvents,
  match: {
    url,
    params: { restaurantId }
  },
  history: { push },
  createCalendar,
  showModal,
  fetchProperty,
  fetchCalendars,
  fetchCalendarEvents,
  updateProperty,
  updateSidebar
}) => {
  const getCalendarEvents = (id, date, published) => {
    const _date = date ? new Date(Date.parse(date)) : new Date();
    const a = new Date(_date.getFullYear(), _date.getMonth(), 1);
    a.setHours(0, 0, 0, 0);

    const b = new Date(_date.getFullYear(), _date.getMonth() + 1, 0);
    b.setHours(23, 59, 59, 999);

    fetchCalendarEvents(id, a, b, published);
  };

  const [viewableCalendars, setViewableCalendars] = useState([]);
  const [viewPublished, setViewPublished] = useState(1);

  const handleSetViewableCalendars = id => {
    const arr = [...viewableCalendars];
    const i = arr.indexOf(id);
    if (i < 0) {
      arr.push(id);
    } else {
      arr.splice(i, 1);
    }
    setViewableCalendars(arr);
  };

  const handleUpdateProperty = (id, calendarDisplayAs) => {
    updateProperty(id, { calendarDisplayAs });
  };

  useEffect(() => {
    fetchProperty(restaurantId).then(item => {
      updateSidebar({ titles: { Restaurant: item.name } });
    });
    fetchCalendars(restaurantId).then(items => {
      setViewableCalendars(items.map(item => item.id));
    });
    return () => {};
  }, []);
  useEffect(() => {
    getCalendarEvents(restaurantId, null, viewPublished);
  }, [viewPublished])
  const [showNewCalendar, setShowNewCalendar] = useState(false);
  const handleSubmitCalendar = data =>
    createCalendar(restaurantId, data).then(() => setShowNewCalendar(false));

  // mapping to localize dates
  const events = calendarEvents.map(event => ({
    ...event,
    start: toLocal(event.start, restaurant.timezone),
    end: toLocal(event.end, restaurant.timezone)
  }));

  return (
    <Layout metaTitle="Calendars">
      <div className="w-full max-w-5xl">
        <h1 className="mb-6">Calendars</h1>
        <div className="mb-12">
          Use this page to create and manage Calendars for {restaurant.name}.
        </div>
        <div className="sm:flex sm:flex-1 justify-start mb-6 md:justify-end">
          {/*
          <Link
            to={`${url}/events/clone`}
            className="btn btn-clear ml-4 no-underline inline-block"
          >
            Clone Event
          </Link>
          */}
          <Link
            to={`${url}/events/new`}
            className="btn no-underline mb-6 sm:mb-0 w-full sm:w-auto"
          >
            + New Event
          </Link>
        </div>
        <div className="flex bg-white rounded shadow-md p-6">
          <div className="w-1/6 mr-4">
            <div className="mb-6">
              <div className="py-5">
                <div className="font-semibold mb-4">Calendars:</div>
                {calendars.map(calendar => (
                  <div
                    key={calendar.id}
                    className="mb-4 flex items-center justify-between"
                  >
                    <div className="flex justify-start">
                      <div className="mr-1">
                        <EyeIcon
                          id="eye-icon"
                          active={viewableCalendars.indexOf(calendar.id) > -1}
                          style={{ width: 20 }}
                          onClick={() => {
                            handleSetViewableCalendars(calendar.id);
                          }}
                        />
                      </div>
                      {calendar.name}
                    </div>
                    <More id={`${calendar.id}-more`}>
                      <MoreMenu id={`${calendar.id}-moremenu`}>
                        <MoreMenuItem
                          id={`${calendar.id}-edit`}
                          onClick={() => {
                            showModal('EditCalendarModal', {
                              propertyId: restaurantId,
                              id: calendar.id,
                              title: calendar.name
                            });
                          }}
                        >
                          Edit
                        </MoreMenuItem>
                        <MoreMenuItem
                          id={`${calendar.id}-delete`}
                          onClick={() =>
                            showModal('DeleteCalendarModal', {
                              propertyId: restaurantId,
                              id: calendar.id,
                              title: calendar.name
                            })}
                        >
                          Delete
                        </MoreMenuItem>
                      </MoreMenu>
                    </More>
                  </div>
                ))}
                {showNewCalendar ? (
                  <AddCalendarForm
                    id="add-restaurant-calendar"
                    onSubmit={handleSubmitCalendar}
                    onClose={() => setShowNewCalendar(false)}
                  />
                ) : (
                  <button
                    type="button"
                    className="btn-link text-blue no-underline hover:underline cursor-pointer"
                    onClick={() => {
                      setShowNewCalendar(true);
                    }}
                  >
                    + New Calendar
                  </button>
                )}
              </div>
              <div className="py-5 border-t">
                <div className="mb-4 font-semibold">Display As:</div>
                <label className="flex items-center mb-2">
                  <input
                    type="radio"
                    value="Grid"
                    name="displayAs"
                    checked={restaurant.calendarDisplayAs === 'month'}
                    onChange={() => {
                      handleUpdateProperty(restaurantId, 'month');
                    }}
                  />
                  <div className="font-normal text-base ml-2">Grid</div>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="List"
                    name="displayAs"
                    checked={restaurant.calendarDisplayAs === 'day'}
                    onChange={() => {
                      handleUpdateProperty(restaurantId, 'day');
                    }}
                  />
                  <div className="font-normal text-base ml-2">List</div>
                </label>
              </div>

              <div className="py-5 border-t">
                <div className="mb-4 font-semibold">Viewing:</div>
                <label className="flex items-center mb-2">
                  <input
                    type="radio"
                    value="Published"
                    name="viewing"
                    defaultChecked
                    onChange={() => {
                      setViewPublished(1);
                    }}
                  />
                  <div className="font-normal text-base ml-2">Published</div>
                </label>
                <label className="flex items-center mb-2">
                  <input
                    type="radio"
                    value="Unpublished"
                    name="viewing"
                    onChange={() => {
                      setViewPublished(0);
                    }}
                  />
                  <div className="font-normal text-base ml-2">Unpublished</div>
                </label>
                <label className="flex items-center mb-2">
                  <input
                    type="radio"
                    value="Both"
                    name="viewing"
                    onChange={() => {
                      setViewPublished(2);
                    }}
                  />
                  <div className="font-normal text-base ml-2">Both</div>
                </label>
              </div>
            </div>
          </div>
          <Calendar
            id="restaurant-calendar"
            events={events}
            startAccessor="start"
            endAccessor="end"
            className="flex-1"
            onSelectEvent={event =>
              push(
                `/restaurants/${restaurantId}/calendars/events/${event.id}/edit`
              )}
            onNavigate={date => {
              getCalendarEvents(restaurantId, date, viewPublished);
            }}
            viewableCalendars={viewableCalendars}
            viewPublished={viewPublished}
          />
        </div>
      </div>
    </Layout>
  );
};

Calendars.propTypes = propTypes;

export default connect(
  (state, props) => ({
    restaurant: state.property.data,
    calendars: state.calendars.data,
    calendarEvents: state.calendarEvents.data,
    isLoading:
      state.property.isLoading ||
      state.calendars.isLoading ||
      state.calendarEvents.isLoading
  }),
  {
    ...modalActions,
    ...propertyActions,
    ...sidebarActions,
    ...categoryActions,
    ...calendarActions,
    ...calendarEventActions
  }
)(Calendars);
