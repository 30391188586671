import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get'
import set from 'lodash/set'
import { toLocal, toUTC } from '../../../utils/date-tz'
import * as propertyActions from '../../../actions/properties';
import * as homePromoActions from '../../../actions/homePromos';
import * as sidebarActions from '../../../actions/sidebar';
import * as imageActions from '../../../actions/images';
import HotelHomePromoForm from '../../../components/HotelHomePromoForm';
import Layout from '../../../layout';
import promoFlags from '../../../utils/promo-flag-options';
import FormNavigator from '../../../components/FormNavigator';

const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired,
  homePromo: PropTypes.object.isRequired,
  updateHomePromo: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchHomePromo: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  setCurrentImage: PropTypes.func.isRequired
};

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'date', title: 'Date & Time' },
  { id: 'image', title: 'Set an Image' },
  { id: 'cta', title: 'CTA (Call to Action)' },
  { id: 'promo', title: 'Promo Flag' },
  { id: 'preview', title: 'Preview + Publish' },
];

const HomePromosEdit = ({
  match: {
    params: { hotelid, id }
  },
  history: { push },
  updateHomePromo,
  hotel,
  homePromo,
  images,
  isLoading,
  fetchProperty,
  fetchHomePromo,
  updateSidebar,
  setCurrentImage
}) => {
  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    fetchHomePromo(hotelid, id);
    return () => {};
  }, []);

  useEffect(() => {
    get(homePromo, 'images', []).forEach(image => {
      setCurrentImage('home-promos-image', image)
    });

    // convert the dates from the server UTC the hotel's timezone adjusted for
    // the local zone
    set(homePromo, 'startsOn', toLocal(homePromo.startsOn, hotel.timezone))
    set(homePromo, 'endsOn', toLocal(homePromo.endsOn, hotel.timezone))

    return () => {};
  }, [homePromo]);

  const handleSubmit = data => {
    const body = {
      ...data,
      // convert the dates to the hotel's timezone adjusted for the local zone
      startsOn: toUTC(data.startsOn, hotel.timezone),
      endsOn: toUTC(data.endsOn, hotel.timezone),
      promo_flag:
        // eslint-disable-next-line no-nested-ternary
        data.promoFlag === 'Other' ? data.promoFlagOther : data.promoFlag,
      images
    };
    return updateHomePromo(hotelid, id, body)
      .then(() => {
        push(`/hotels/${hotelid}/promos`);
      })
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  return (
    <Layout type="Specials" metaTitle="Edit Home Promo">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <Link
            to={`/hotels/${hotel.id}/promos`}
            className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
          >
            &lt; Back to Home Promos
          </Link>
          <h1 className="mb-6">{homePromo.title}</h1>
          <div className="mb-16">
            Use this page to edit {homePromo.title} Home Promo for {hotel.name}.
          </div>

          <HotelHomePromoForm
            id="edit-promos"
            hotel={hotel}
            initialValues={{
              ...homePromo,
              startsOn: homePromo.startsOn
                ? new Date(homePromo.startsOn)
                : homePromo.startsOn,
              endsOn: homePromo.endsOn
                ? new Date(homePromo.endsOn)
                : homePromo.endsOn,
              // eslint-disable-next-line no-nested-ternary
              promoFlag: !homePromo.promoFlag
                ? 'None'
                : promoFlags.findIndex(
                    title => title.id === homePromo.promoFlag
                  ) === -1
                ? 'Other'
                : homePromo.promoFlag,
              promoFlagOther:
                promoFlags.findIndex(
                  title => title.id === homePromo.promoFlag
                ) === -1
                  ? homePromo.promoFlag
                  : undefined
            }}
            onSubmit={handleSubmit}
            enableReinitialize
            keepDirtyOnReinitialize
          />
        </div>
      )}
      <FormNavigator
        id="edit-promos-navigator"
        sections={sections}
        className="hidden xl:block mt-32 pt-16"
      />
    </Layout>
  );
};

HomePromosEdit.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    homePromo: state.homePromo.data,
    images: get(state, 'images.home-promos-image.items', [])
      .filter(item => item.file)
      .map(item => ({ assetId: item.file.id, alt: item.alt })),
    isLoading: state.property.isLoading || state.homePromo.isLoading
  }),
  {
    ...propertyActions,
    ...homePromoActions,
    ...sidebarActions,
    ...imageActions
  }
)(HomePromosEdit);
