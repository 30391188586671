import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get'
import set from 'lodash/set'
import { toLocal, toUTC } from '../../../utils/date-tz';
import * as propertyActions from '../../../actions/properties';
import * as homePromoActions from '../../../actions/homePromos';
import * as sidebarActions from '../../../actions/sidebar';
import HotelHomePromoForm from '../../../components/HotelHomePromoForm';
import Layout from '../../../layout';
import FormNavigator from '../../../components/FormNavigator';

const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired,
  createHomePromo: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired
};

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'date', title: 'Date & Time' },
  { id: 'image', title: 'Set an Image' },
  { id: 'cta', title: 'CTA (Call to Action)' },
  { id: 'promo', title: 'Promo Flag' },
  { id: 'preview', title: 'Preview + Publish' },
];

const HomePromosNew = ({
  match: {
    params: { hotelid }
  },
  history: { push },
  createHomePromo,
  hotel,
  images,
  isLoading,
  fetchProperty,
  updateSidebar
}) => {
  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    return () => {};
  }, []);

  if (isLoading) return <></>;

  const handleSubmit = data => {
    let { doesNotEnd, endsOn } = data;
    if (doesNotEnd) {
      endsOn = new Date(2120, 0, 1);
    }
    const homePromo = {
      ...data,
      startsOn: toUTC(data.startsOn, hotel.timezone),
      endsOn: toUTC(endsOn, hotel.timezone),
      promo_flag:
        // eslint-disable-next-line no-nested-ternary
        data.promoFlag === 'Other' ? data.promoFlagOther : data.promoFlag,
      images
    };
    return createHomePromo(hotelid, homePromo)
      .then(() => {
        push(`/hotels/${hotelid}/promos`);
      })
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  return (
    <Layout type="Home Promos" metaTitle="New Home Promo">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <Link
            to={`/hotels/${hotel.id}/promos`}
            className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
          >
            &lt; Back to Home Promos
          </Link>
          <h1 className="mb-6">New Home Promo</h1>
          <div className="mb-16">
            Use this page to create Home Promos for {hotel.name}.
          </div>

          <HotelHomePromoForm
            id="new-promos"
            hotel={hotel}
            initialValues={{
              doesNotEnd: true,
              cta: {
                opensInNewWindow: false,
                addDatePickerPopup: false,
                advancedPurchaseDays: 0,
                minimumNightStay: 1
              },
              promoFlag: 'None',
              archived: false
            }}
            onSubmit={handleSubmit}
          />
        </div>
      )}
      <FormNavigator
        id="new-specials-navigator"
        sections={sections}
        className="hidden xl:block mt-32 pt-16"
      />
    </Layout>
  );
};

HomePromosNew.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    homePromo: state.homePromo.data,
    images: get(state, 'images.home-promos-image.items', [])
      .filter(item => item.file)
      .map(item => ({ assetId: item.file.id, alt: item.alt })),
    isLoading: state.property.isLoading
  }),
  { ...propertyActions, ...homePromoActions, ...sidebarActions }
)(HomePromosNew);
