import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get'
import set from 'lodash/set'
import { toLocal, toUTC } from '../../../utils/date-tz';
import Layout from '../../../layout';
import * as propertyActions from '../../../actions/properties';
import * as sidebarActions from '../../../actions/sidebar';
import * as categoriesActions from '../../../actions/categories';
import * as faqActions from '../../../actions/faqs';
import HotelFAQForm from '../../../components/HotelFAQForm';
import FormNavigator from '../../../components/FormNavigator';

const propTypes = {
  hotel: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  faqs: PropTypes.array.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  createFAQ: PropTypes.func.isRequired
};

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'date', title: 'Date & Time' },
  { id: 'preview', title: 'Preview & Publish' },
];

const New = ({
  hotel,
  categories,
  match: {
    params: { hotelid }
  },
  history: { push },
  faqs,
  fetchProperty,
  updateSidebar,
  fetchCategories,
  createFAQ
}) => {
  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    fetchCategories(hotelid);
    return () => {};
  }, []);

  const handleSubmit = data => {
    const faq = { ...data, sortIndex: faqs.length };

    // check doesNotEnd and set it, this may not be toggled when creating new
    if (faq.doesNotEnd) {
      faq.endsOn = new Date(2120, 0, 1);
    }

    return createFAQ(hotelid, faq)
      .then(() => push(`/hotels/${hotelid}/faqs`))
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  return (
    <Layout metaTitle="New FAQ">
      <div className="w-full max-w-3xl">
        <Link
          to={`/hotels/${hotelid}/faqs`}
          className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
        >
          &lt; Back to FAQs
        </Link>
        <h1 className="mb-6">New FAQ</h1>
        <div className="mb-16">
          Use this page to create FAQs for {hotel.name}.
        </div>

        <HotelFAQForm
          id="new-faq-form"
          onSubmit={handleSubmit}
          categories={categories}
          initialValues={{
            doesNotEnd: true,
            categoryId: get(categories, [0, 'id'])
          }}
          enableReinitialize
          keepDirtyOnReinitialize
        />
      </div>
      <FormNavigator
        id="new-weddings-navigator"
        sections={sections}
        className="hidden xl:block mt-32 pt-16"
      />
    </Layout>
  );
};

New.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    categories: state.categories.data,
    faqs: state.faqs.data,
    isLoading: state.property.isLoading || state.categories.isLoading
  }),
  { ...propertyActions, ...sidebarActions, ...categoriesActions, ...faqActions }
)(New);
